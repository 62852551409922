@import "typography";
@import "_variables";
@import "_icons";

$break-small: 320px;
$break-medium: 530px;
$break-large: 1200px;

// h4,h5,h6 {
// 	font-weight: 600;
// }

body {
	@media screen and (min-width: $break-medium) {
		margin-top: 5rem;
	}
}

.subtitle {
	font-weight: 200;
	margin-top: -1.25rem;
	opacity: 0.8;
}

.content {
	max-width: 960px;
	margin: auto;
	padding: 1rem;
}

.profile-photo {
	margin-left: 3rem;

	img {
		border-radius: 50%;
		object-fit: cover;
		width: 25vw;
		height: 25vw;
		min-width: 150px;
		min-height: 150px;
		max-width: 300px;
		max-height: 300px;
	}
}

.contact-icons > *:not(:last-child) {
	margin-right: 1rem;
}

.bio {
	display:flex;
	@media screen and (max-width: $break-medium) {
		flex-wrap: wrap;
		flex-direction: column-reverse;
		.profile-photo {
			margin: 0;
		}
	}
}