.icon {
	display: inline-block;
	width: 40px;
	height: 40px;
	fill: $icon-color;
	transition: fill $icon-fade-time linear;
}

.icon:hover,
.icon:focus {
	fill: $icon-hover-color;
}


@mixin transition($property, $time, $timing-function) {
	transition: $property $time $timing-function;
}

.icon-link {
	color: $icon-color;
	@include transition(opacity, $icon-fade-time, linear);
}

.icon-header:hover,
.icon-header:focus {
	fill: $icon-color;
}

.icon-link,
.icon-link:hover,
.icon-link:focus {
	opacity: $icon-initial-opacity;
	text-decoration: none;
}

.icon-link:hover,
.icon-link:focus {
	opacity: $icon-hover-opacity;
}
